/**
 * ConferenceContainer component props interface.
 * 
 * @interface ConferenceContainerProps
 * @property {React.ReactNode} footer - The footer content to be displayed.
 * @property {number} [id] - Optional ID for the conference.
 * @property {boolean} [showRegistration] - Flag to show the registration button.
 * @property {boolean} [showLocale] - Flag to show the locale switcher.
 * @property {(info: PageContainerInfo) => ReactNode} [children] - Optional children function that receives PageContainerInfo.
 */

/**
 * ConferenceContainer component.
 * 
 * @param {ConferenceContainerProps} props - The props for the ConferenceContainer component.
 * @returns {JSX.Element} The rendered ConferenceContainer component.
 * 
 * @example
 * <ConferenceContainer
 *   footer={<FooterComponent />}
 *   id={123}
 *   showRegistration={true}
 *   showLocale={true}
 * >
 *   {(info) => <ChildComponent onLogin={info.onLogin} />}
 * </ConferenceContainer>
 */
import { useRouter } from 'next/router';
import { FC, ReactNode, useCallback, useMemo, useContext } from 'react';

// ========================= 组件 =========================
import ConferenceHeader from './components/ConferenceHeader';
import AppEnKF from "@/components/AppEnKF";
import AppKF from "@/components/AppKF";
import IconFont from "@/components/iconfont";
import Image from '@/components/image';
import Link from '../link';

// ========================= 数据交互 =========================

// ========================= 全局数据 =========================

// ========================= 常量 =========================

// ========================= 工具 =========================
import useLogin from '@/hooks/useLogin';
import { token } from '@/store/atoms/global';
import { useAtomValue } from 'jotai';
import useRouterPush from '@/hooks/useRouterPush';
import ConferenceLayoutContext from '@/context/conference';
import classnames from 'classnames';

// ========================= 样式 =========================
import styles from './ConferenceContainer.module.css';
import useScrollIntoView from '@/hooks/useScrollIntoView';
import { useTranslation } from 'next-i18next';

// ========================= 变量 =========================

// ========================= 类型 =========================

interface ConferenceContainerProps {
    footer: React.ReactNode;
    id?: number;
    showRegistration?: boolean;
    showLocale?: boolean;
    showTop?: boolean;
    disabledRegistration?: boolean;
    children?: (info: PageContainerInfo) => ReactNode;
}

const ConferenceContainer: FC<ConferenceContainerProps> = ({ footer, id, showLocale, showRegistration, showTop, disabledRegistration, children }) => {
    const { t } = useTranslation('conference');
    const { locale } = useRouter();

    const { indexQrCode, articleId, emailPath, agendaPath, disabledClosable, pcFloatingButtonName, pcFloatingButtonId } = useContext(ConferenceLayoutContext)
    // 使用push跳转页面
    const push = useRouterPush();

    const loginButtonHandler = () => {
        if (locale === 'en') {
            beforeLogin();
        }
    }
    // 登录和首页按钮 中文时是首页 英文时是登录
    const loginButton = useMemo(() => (
        locale === 'en' ? (
            <button
                className={styles['login']}
                onClick={loginButtonHandler}
            >
                Log in
            </button>
        ) : (<Link target="_blank" href="/">首页</Link>)
    ), [locale]);

    const { beforeLogin, LoginInfoContent, LoginModuleContent, UserInfoContent } = useLogin({
        loginButton,
        disabledClosable,
        id: id || 0,
    });


    const { scrollToElement } = useScrollIntoView({
        behavior: 'smooth',
        block: 'center',
        onScrollEnd: () => {
            history.replaceState(null, '', '#registration');
        }
    })

    const tokenKey = useAtomValue(token);
    // 点击注册按钮事件
    const onRegistrationClick = useCallback(() => {
        // 判断是否是英文
        if (locale === 'en') {
            !tokenKey && beforeLogin();
            tokenKey && push(`/conference/registration/${id}`, '', { locale: 'en' });
        } else {
            // 获取dom id为=registration的元素
            const registration = document.getElementById('registration');
            if (!registration) return;
            scrollToElement(registration);
        }
    }, [beforeLogin, tokenKey]);

    return (
        <div className={styles['conference-container']}>
            <ConferenceHeader
                showLocale={showLocale}
                showRegistration={showRegistration}
                disabledRegistration={disabledRegistration}
                onRegistrationClick={onRegistrationClick}
            >
                {LoginInfoContent}
            </ConferenceHeader>

            {/* 传入children */}
            {children && children({ onLogin: beforeLogin })}
            {LoginModuleContent}
            {UserInfoContent}


            <aside className={styles['right']}>
                {
                    locale === 'zh' && (
                        <article className={styles['qrcode']}>
                            <div className={styles['qrcode-image-wrapper']}>
                                <Image src={indexQrCode} alt="" fill />
                            </div>
                            <span>微信小程序</span>
                        </article>
                    )
                }
                {
                    (articleId || (pcFloatingButtonName && pcFloatingButtonId)) && (
                        <div className={classnames(styles['conference_notice'], {
                            [styles['conference_notice-en']]: locale === 'en'
                        })}>
                            {
                                articleId && (
                                    <Link href={`/detail/news/${articleId}`} target="_blank" locale={locale}>
                                        <article>
                                            <IconFont type="icon-tanhaotishi" className={styles['notice-icon']} />
                                            <span className={styles['notice-text']}>{t('Notes')}</span>
                                        </article>
                                    </Link>
                                )
                            }
                            {
                                pcFloatingButtonName && pcFloatingButtonId && (
                                    <Link href={`/detail/news/${pcFloatingButtonId}`} target="_blank">
                                        <article>
                                            <IconFont type="icon-lianjie2" className={styles['notice-icon']} />
                                            <span className={styles['notice-text']}>{pcFloatingButtonName}</span>
                                        </article>
                                    </Link>
                                )
                            }
                        </div>
                    )
                }
                {
                    locale === 'zh' ? (
                        <AppKF relative />
                    ) : (
                        <AppEnKF
                            emailPath={emailPath}
                            agendaPath={agendaPath}
                        />
                    )
                }
                {/* 置顶按钮 */}
                {
                    showTop ? (
                        <div className={styles['top']} onClick={() => scrollToElement(document.body)}>
                            <IconFont type="icon-shang" className={styles['top-icon']} />
                        </div>
                    ) : null
                }
            </aside>
            {/* 传入footer */}
            {footer}
        </div>
    );
}
export default ConferenceContainer;