import { FC } from 'react';

// ========================= 组件 =========================
import IconFont from '../iconfont'

// ========================= 数据交互 =========================

// ========================= 全局数据 =========================

// ========================= 常量 =========================

// ========================= 工具 =========================
import classnames from 'classnames';

// ========================= 样式 =========================
import styles from './index.module.css';

// ========================= 变量 =========================

// ========================= 类型 =========================

interface AppKFProps {
    relative?: boolean;
}

const AppKF: FC<AppKFProps> = ({ relative = false }) => (
    <div className={classnames(styles['kf'], { [styles['kf-relative']]: relative })}>
        <a
            href="https://work.weixin.qq.com/kfid/kfcd0155d3c55ed5bdc"
            target="_blank"
        >
            <IconFont type="icon-kefu" className={styles['kf-icon']} />
            客服
        </a>
    </div>
)
export default AppKF;